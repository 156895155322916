<template>
    <el-dialog
        class="import-result-dialog"
        :class="!isTextContext ? 'with-img' : ''"
        :visible.sync="isShow"
        :title="isOccupy ? '抢占结果' : '导入结果'"
        :width="isTextContext ? '420px' : '540px'"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <template v-if="!isTextContext">
            <div class="import-result-dialog-left">
                <img src="~@src/assets/images/import-success.png" alt="导入结果">
            </div>
            <div class="import-result-dialog-right">
                <h2 class="font-green">导入结果</h2>
                <p>批量选择：<span class="font-green">{{importResult.total}}</span></p>
                <p>成功导入：<span class="font-green">{{importResult.successCount}}</span></p>
                <template v-if="importResult.failCount > 0">
                    <p class="font-indent">失败：<span class="font-grey">{{importResult.failCount}}</span></p>
                    <div class="error-tip">
                        失败原因：{{errorMessage}}
                        <span
                            class="compare-btn text-operate-btn"
                            v-if="isShowCompareBtn && importResult.total == 1"
                            @click="goToCompare"
                        >
                            去对比>>
                        </span>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="import-result-simple">
                <p>抢占成功 {{importResult.successCount}} 份</p>
                <template v-if="importResult.failCount > 0">
                    <p>失败 {{importResult.failCount}} 份</p>
                    <div>
                        失败原因：{{errorMessage}}
                        <span
                            class="compare-btn text-operate-btn"
                            v-if="isShowCompareBtn"
                            @click="goToCompare"
                        >
                            去对比>>
                        </span>
                    </div>
                </template>
            </div>
        </template>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm()">
                {{isTextContext ? '确认': '完成'}}
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'import-result-dialog',
    props: {
        // 是否是工作池表格页面
        isTextContext: false,
        isOccupy: false
    },
    data() {
        return {
            isShow: false,
            importResult: {
                total: 0,
                successCount: 0,
                failCount: 0
            },
            errorMessage: '',
            isShowCompareBtn: false,
            resumeId: "",
            repeatResumeId: ""
        }
    },
    methods: {
        show(importResult) {
            this.importResult = importResult;
            if(importResult.total > 1 && importResult.successCount !== importResult.total) {
                this.errorMessage = "简历信息不完整，姓名、手机、公司、职位、城市为必须项！";
            } else {
                let singleImportItem = importResult.list[0];
                if(!singleImportItem.successed) {
                    this.errorMessage = singleImportItem.errorMessage;
                    if(singleImportItem.repeatResumeId && singleImportItem.errorMessage.indexOf('该人才已存在于人才库') > -1) {
                        this.isShowCompareBtn = true;
                        this.resumeId = singleImportItem.tbdResumeId;
                        this.repeatResumeId = singleImportItem.repeatResumeId;
                    }
                }
            }
            this.isShow = true;
        },
        goToCompare() {
            this.isShow = false;
            this.$emit('import-then-compare', {
                resumeId: this.resumeId,
                repeatResumeId: this.repeatResumeId
            });
        },
        handleConfirm(){
            this.isShow = false;
            this.$emit('import-confirm')
        }
    }
}
</script>

<style lang="scss">
.import-result-dialog {
    &.with-img {
        .el-dialog__body {
            display: flex;
            min-height: 223px;
            padding: 30px 40px 10px 60px;
        }
    }
    &-left {
        width: 170px;
        margin-right: 40px;
        img {
            max-width: none;
            max-height: none;
        }
    }
    &-right {
        color: #666;
        flex-grow: 1;

        h2 {
            font-size: 21px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 10px;
            line-height: 18px;
        }
        .font-green {
            color: $primary;
        }
        .font-grey {
            color: #999;
        }
        .font-indent {
            text-indent: 2;
        }
        .error-tip {
            color: #e21313;
        }
    }
    .compare-btn {
        margin-left: 10px;
    }
    .import-result-simple {
        line-height: 24px;
        p {
            margin: 0;
        }
    }
}
</style>
